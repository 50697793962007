import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Terms of Use for Prospect and Scope | IrisVR'
const description =
  "Learn the terms of use for IrisVR's software for virtual reality (VR), including Prospect and Scope."
const url = `${getWindowLocationOrigin()}/terms`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Prospect2.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
