import React from 'react'
import PropTypes from 'prop-types'

const TextBlock = ({ title, text }) => (
  <section className="textblock">
    <h4 className="title">{title}</h4>
    <p>---</p>
    <p className="text">{text}</p>
  </section>
)

TextBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default TextBlock
