import React from 'react'
import PropTypes from 'prop-types'

const SubTextBlock = ({ title, text }) => (
  <section className="textblock">
    <p className="text">
      <b className="title">{title}</b> {text}
    </p>
  </section>
)

SubTextBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default SubTextBlock
