import React from 'react'
import { Helmet } from '../components/Shared'
import Terms, { helmetProps } from '../components/Policy/Terms'

export default React.memo(() => (
  <>
    <Helmet {...helmetProps} />
    <Terms />
  </>
))
